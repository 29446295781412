import React, { useState, useEffect } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { Tooltip, Box } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import EclipseSpinner from "../EclipseSpinner";
import FullPageGridContainer from "../layout/FullPageGridContainer";
import Header from "../layout/Header";
import ErrorMessage from "../pages/ErrorMessage";
import NotFound404 from "../pages/NotFound404";
import InventoryAdvancedSearch from "./InventoryAdvancedSearch";
import { useLocation } from "react-router-dom";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const reportCols = [
  { field: "id", headerName: "id", hide: true },
  { renderCell, field: "itemid", headerName: "SKU", minWidth: 90 },
  {
    renderCell,
    field: "displayname",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
  },
  {
    renderCell,
    field: "location",
    headerName: "Location",
    flex: 1,
    minWidth: 150,
  },
  { field: "quantityavailable", headerName: "Available", minWidth: 100, type: "number" },
  //{ field: "quantitybackordered", headerName: "Backordered", minWidth: 150, type: "number" },
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-between">
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </Box>
      <InventoryAdvancedSearch />
    </GridToolbarContainer>
  );
};

const InventoryReport = (props) => {
  const [items, setItems] = useState([]);
  const location = useLocation()
  const params = Object.fromEntries(
    new URLSearchParams(location.search)
  );
  const netsuiteColumns = JSON.stringify([
    "itemid",
    "displayname",
    "inventorylocation",
    "locationquantityavailable",
    //"locationquantitybackordered",
  ]);

  // Set filters for netsuite api
  const netsuiteFilters = [];
  if (params.location)
    netsuiteFilters.push("AND", ["inventorylocation", "is", params.location]);
  if (params.sku)
    netsuiteFilters.push("AND", ["itemid", "contains", params.sku]);
  if (params.displayname)
    netsuiteFilters.push("AND", ["displayname", "contains", params.displayname]);
  if (params.itemcollection)
    netsuiteFilters.push("AND", ["itemcollection", "anyof", params.itemcollection]);


  // Set the url
  let url = `${process.env.REACT_APP_BASE_URL}/api/reports/inventory/?results=${netsuiteColumns}`;
  if (netsuiteFilters) {
    url += `&params=${JSON.stringify(netsuiteFilters)}`;
  }

  const { isLoading, error, data: json } = useFetch({ url });

  useEffect(() => {
    if (json) {
      const { result, data = [] } = json;

      if (result === "success") {
        setItems(
          data.map(
            (
              {
                displayname,
                locationquantityavailable,
                //locationquantitybackordered,
                inventorylocation: [{text: location} = {}] = [],
                itemid,
              },
              index
            ) => ({
              id: index,
              itemid,
              displayname,
              location,
              quantityavailable: locationquantityavailable || 0,
              //quantitybackordered: locationquantitybackordered || 0,
            })
          )
        );
      }
    }
  }, [json]);

  window.document.title = "Inventory Report | Suite Sales";

  if (error) return <ErrorMessage error={error} />;
  if (isLoading) return <EclipseSpinner />;
  if (!json?.data) return <NotFound404 />;

  return (
    <>
      <Header header="Inventory" className="justify-between" />
      <FullPageGridContainer>
        <DataGridPro
          columns={reportCols}
          rows={items}
          loading={isLoading}
          density="standard"
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </FullPageGridContainer>
    </>
  );
};

export default InventoryReport;
